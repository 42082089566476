<template>
    <div class="add-or-edit">
        <el-dialog
            :title="detailInfo.id ? '编辑角色' : '新增角色'"
            :visible.sync="visible"
            center
            width="450px"
            @close="QualityDialogClose"
        >
            <!-- 文本输入表单 start -->
            <el-form
                ref="subitForm"
                :model="subitForm"
                class="demo-ruleForm"
                label-width="20%"
                :rules="rules"
            >
                <el-form-item label="角色名称" prop="name">
                    <el-input v-model="subitForm.name" size="medium " style="width:90%" />
                </el-form-item>
                <el-form-item label="角色备注" size="medium " prop="remarks">
                    <el-input v-model="subitForm.remarks" style="width:90%" maxlength="64" />
                </el-form-item>
            </el-form>
            <!-- 文本输入表单 end -->

            <!-- tabs 权限和权限范围 start -->
            <el-tabs v-model="activeName" style="padding: 0 4%;" @tab-click="handleClick">
                <el-tab-pane label="菜单权限" name="0">
                    <div class="role-page">
                        <el-tree
                            ref="menuDataTree"
                            :data="menuData"
                            show-checkbox
                            default-expand-all
                            node-key="permissionId"
                            :check-strictly="true"
                            highlight-current
                            :props="defaultProps"
                        />
                    </div>
                </el-tab-pane>
                <!-- 分隔 新增的授权范围  start-->
                <el-tab-pane label="授权范围" name="1">
                    <el-select
                        v-model="roleArea"
                        class="width-setting"
                        placeholder="请选择"
                        size="medium"
                    >
                        <el-option
                            v-for="item in roleAreaArr"
                            :key="item.number"
                            :label="item.cnVal"
                            :value="item.number"
                        />
                    </el-select>
                    <el-collapse-transition>
                        <div v-show="roleArea == 4" class="role-page">
                            <div>
                                <el-tree
                                    ref="tree"
                                    :data="departMenu"
                                    show-checkbox
                                    default-expand-all
                                    node-key="id"
                                    highlight-current
                                    :props="defaultProps1"
                                    :check-strictly="true"
                                    :default-checked-keys="selected"
                                    @check-change="authority1"
                                />
                            </div>
                        </div>
                    </el-collapse-transition>
                </el-tab-pane>
                <!-- 分隔 新增的授权范围  end-->
            </el-tabs>
            <!-- tabs 权限和权限范围 end -->

            <!-- 表单按钮 start -->
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm()">保存</el-button>
                <el-button @click="cancelSubmit()">取 消</el-button>
            </span>
            <!-- 表单按钮 end -->
        </el-dialog>
    </div>
</template>

<script>
import { permissions, grants, add_role, modify_role } from '@/api/character.js';
export default {
    components: {},
    props: {
        showAddOrEdit: {
            default: false,
        },
    },
    data() {
        return {
            menuData: [],
            departMenu: [],
            visible: false,
            subitForm: {
                name: '',
                remarks: '',
                scopeType: '',
                permissionIds: [],
                departmentIds: [],
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: '请输入角色名称',
                        trigger: 'blur',
                    },
                    {
                        min: 1,
                        max: 32,
                        message: '长度是1-32个字符之间',
                        trigger: 'blur',
                    },
                    { validator: this.formValidation.heading, trigger: 'blur' },
                ],
            },
            activeName: 0,
            // 权限树的数据
            // 授权范围选项
            roleAreaArr: [],
            // 权限范围的值
            roleArea: 1,
            defaultProps: {
                children: 'children',
                label: 'resourceName',
            },
            defaultProps1: {
                children: 'children',
                label: 'name',
            },
            detailInfo: {},
            check: [], //菜单权限选中
            selected: [],
        };
    },
    // 从字典中获取内容
    created() {
        this.roleAreaArr = this.common.allValueNeed('role-grant-scope-type');
        console.log(this.roleAreaArr, 'roleAreaArrroleAreaArrroleAreaArr');
    },
    // 获取列表
    mounted() {
        console.log(this.$refs.menuData);
        this.getPermission();
    },
    methods: {
        // 初始化 父组件调用子组件方法
        init(info) {
            this.detailInfo = info;
            this.getPermission();
            this.subitForm.name = info.name;
            this.subitForm.remarks = info.remarks;
        },
        // 关闭dialog转回父组件
        QualityDialogClose() {
            this.$emit('update:showAddOrEdit', false);
        },
        getMenuData() {},
        // 点击tabs切换
        handleClick() {
            console.log(this.activeName);
        },
        // 取消操作
        cancelSubmit() {
            this.visible = false;
            this.$refs.subitForm.resetFields();
            this.check = [];
            this.$refs.menuDataTree.setCheckedKeys(this.check);
            (this.roleArea = 1), (this.selected = []);
        },
        // 获取权限数据
        getPermission() {
            let roleId = this.detailInfo ? this.detailInfo.id : '';
            permissions({ roleId }).then((res) => {
                if (res.code == 0) {
                    this.menuData = res.data.list;
                    console.log(this.detailInfo.id);
                    if (this.detailInfo.id) {
                        // 根据已有的数据判断选中的值返回
                        this.findAllSelect(res.data.list);
                        this.$refs.menuDataTree.setCheckedKeys(this.check);
                    }
                } else {
                    this.$message.error(res.message);
                }
                console.log(res, 'permissionseeeee');
            });
            grants({ roleId }).then((res) => {
                console.log('dsadsasdads', res);
                if (res.code == 0) {
                    console.log(res.data.departmentList);
                    this.departMenu = res.data.departmentList;
                    if (res.data.scopeType == 3) {
                        this.roleArea = '请选择';
                    } else {
                        this.roleArea = res.data.scopeType;
                    }

                    // console.log( this.roleArea,' this.roleArea')
                    this.selected = [];
                    this.find2(res.data.departmentList, 1);
                } else {
                    this.$message.error(res.error);
                }
            });
        },
        //授权范围判断计算获取已选择的节点id
        authority1(data, status, status1) {
            if (status1 == true || status == true) {
                this.selected.push(data.id);
                console.log(this.selected, 'selected');
            } else if (status1 == false || status == false) {
                let num = this.selected.findIndex(function (item) {
                    return item === data.id;
                });
                this.selected.splice(num, 1);
            }
        },
        //授权范围渲染已选择的节点
        find2(arr, type) {
            for (let i = 0; i < arr.length; i++) {
                if (arr[i].children.length > 0) {
                    if (arr[i].selected === type) {
                        this.selected.push(arr[i].id);
                    }
                    this.find2(arr[i].children, type);
                } else if (arr[i].selected === type) {
                    this.selected.push(arr[i].id);
                }
            }
        },
        // 提交内容信息
        submitForm() {
            this.$refs.subitForm.validate((valid) => {
                if (valid) {
                    let selectArr = this.$refs.menuDataTree.getCheckedNodes();
                    let treeIds = [];
                    selectArr.forEach((item) => {
                        treeIds.push(item.permissionId);
                    });
                    console.log(treeIds);
                    this.subitForm.permissionIds = treeIds;
                    this.subitForm.scopeType = this.roleArea;
                    this.subitForm.departmentIds = this.selected;
                    console.log(this.detailInfo.id);
                    if (this.detailInfo.id) {
                        this.subitForm.roleId = this.detailInfo.id;
                        // console.log(this.subitForm);
                        console.log(this.roleArea);
                        if (this.roleArea === '请选择')
                            return this.$message.error('请选择授权范围');
                        modify_role(this.subitForm).then((res) => {
                            if (res.code == 0) {
                                this.$message.success('已修改角色');
                                this.getPermission();
                                this.$parent.xr();
                                this.cancelSubmit();
                                this.visible = false;
                            } else {
                                res.$message.error(res.message);
                            }
                        });
                    } else {
                        console.log('this.subitForm', this.subitForm);
                        add_role(this.subitForm).then((res) => {
                            if (res.code == 0) {
                                console.log(res);
                                this.$message.success('已创建角色');
                                this.getPermission();
                                this.$parent.xr();
                                this.cancelSubmit();
                                this.visible = false;
                            } else {
                                this.$message.error(res.message);
                            }
                        });
                    }
                } else {
                    return false;
                }
            });
            // this.cancelSubmit();
        },
        findAllSelect(arr) {
            arr.forEach((item) => {
                if (item.selected == 1) {
                    this.check.push(item.permissionId);
                }
                if (item.children.length > 0) {
                    if (item.selected == 1) {
                        this.check.push(item.permissionId);
                    }
                    this.findAllSelect(item.children);
                }
            });
            return this.check;
        },
    },
    watch: {
        showAddOrEdit() {
            this.visible = this.showAddOrEdit;
        },
    },
};
</script>
<style lang="less" scoped>
.add-or-edit {
    overflow: hidden;
}
/deep/ .el-tabs__nav-scroll {
    display: flex;
    justify-content: center;
}
/deep/ .el-tabs__item {
    padding: 0 50px;
}
.role-page {
    border: 1px solid #aaaaaa;
    padding: 15px;
    height: 300px;
    overflow-y: scroll;
    border-radius: 10px;
}
.width-setting {
    width: 100%;
    margin-bottom: 10px;
    /deep/ .el-input {
        width: 100%;
    }
}
/deep/ .el-dialog {
    height: 700px;
}
</style>
